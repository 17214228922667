export type InputSize = 'small' | 'medium';

export type useInputProps = {
  name: string;
  idPrefix?: string;
  readOnly?: boolean;
  invalid?: boolean;
  disabled?: boolean;
  error?: string;
  label?: string;
  required?: boolean;
  size?: InputSize;
};

export const useInput = ({
  idPrefix,
  name,
  readOnly,
  invalid,
  error,
  label,
  required,
  disabled,
  size,
}: useInputProps) => {
  const id = (idPrefix || '') + name;

  return {
    inputProps: {
      id,
      name,
      readOnly,
      'aria-invalid': invalid ? ('true' as const) : ('false' as const),
      disabled,
    },
    inputWrapperProps: {
      id,
      error,
      label,
      required,
      size,
    },
  };
};
