import axios, { AxiosError } from 'axios';

export type PaginatedResult<T> = {
  count: number;
  limit: number;
  offset: number;
  totalPages: number;
  currentPage: number;
  results: T[];
};

export type Option<T> = {
  label: string;
  value: T;
  disabled?: boolean;
};

export type AnyObject = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any;
};

export const hasOwnProperty = <T extends object>(obj: T, key: PropertyKey): key is keyof T => {
  return key in obj;
};

export function assertIsDefined<T>(val: T, errorMsg: string): asserts val is NonNullable<T> {
  if (val === undefined || val === null) {
    throw new Error(errorMsg);
  }
}

export const isDefined = <T>(value: T): value is Exclude<T, null | undefined> => {
  return value !== undefined && value !== null;
};

export const isString = (value: unknown): value is string => {
  return typeof value === 'string' || value instanceof String;
};

export const isFileOrBlob = (value: unknown): value is Blob | File => {
  return value instanceof Blob || value instanceof File;
};

export function isNumber(value: unknown): value is number {
  return typeof value === 'number' || value instanceof Number;
}

export function isBoolean(value: unknown): value is boolean {
  return typeof value === 'boolean' || value instanceof Boolean;
}

export function isPrimitivesArray(value: unknown | null): value is unknown[] {
  return !!value && Array.isArray(value) && (!value.length || typeof value[0] !== 'object');
}

export function isStringArray(value: unknown): value is string[] {
  return isPrimitivesArray(value) && (!value.length || typeof value[0] === 'string' || value[0] instanceof String);
}

export const isAxiosError = <T>(error: unknown): error is AxiosError<T> => {
  return axios.isAxiosError(error);
};
