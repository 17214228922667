export default {
  msg_default_website_title: 'Datame',
  msg_default_meta_title: 'Default meta title',
  msg_default_meta_description: 'Default meta description',

  msg_common_no_data: 'No data',
  msg_common_log_out: 'Log out',

  msg_validation_max_day_period: 'Max period: {{days}} days',
  msg_validation_start_date_after_end_date: 'Start date cannot be after end date',
  msg_validation_required: 'Required',
  msg_validation_field_not_unique: 'Value must be unique',
  msg_validation_bad_UUID: 'Please enter a valid UUID',

  msg_login_title: 'Login',

  msg_go_back_home_button: 'Go back Home',

  msg_pagination_page_description: '{{from}}-{{to}} from {{total}}',
  msg_pagination_page_size_description: 'Shown on page:',
  msg_pagination_page_size_input_value: '{{count}} rows',

  msg_select_default_placeholder: 'Select',

  msg_card_admin_email: 'Admin Email:',
  msg_card_app_id: 'App ID:',
  msg_card_app_token: 'App Token:',
  msg_card_button_demo_app: 'Open',
  msg_card_button_see_app_users: 'See App users',

  msg_siderbar_tab_application: 'Application',
  msg_siderbar_tab_existing_users: 'Existing users',
  msg_siderbar_tab_existing_integrations: 'Existing integrations',
  msg_siderbar_tab_datahub: 'Datahub',

  msg_provider_apple: 'Apple Health',
  msg_provider_dexcom: 'Dexcom',
  msg_provider_dexcom_sandbox: 'Dexcom Sandbox',
  msg_provider_omron: 'Omron',
  msg_provider_suunto: 'Suunto',
  msg_provider_fitbit: 'Fitbit',
  msg_provider_garmin: 'Garmin',
  msg_provider_google_fit: 'Google Fit',
  msg_provider_oura: 'Oura',
  msg_provider_polar: 'Polar',
  msg_provider_spotify: 'Spotify',
  msg_provider_strava: 'Strava',
  msg_provider_whoop: 'Whoop',
  msg_provider_withings: 'Withings',


  msg_existing_integration_table_application: 'Application',
  msg_existing_integration_table_user: 'User',
  msg_existing_integration_table_integration: 'Integration',
  msg_existing_integration_table_created_at: 'Created at',
  msg_existing_integration_table_updated_at: 'Updated at',
  //Pages
  msg_home_page_title: 'Home page',
  msg_not_found_page_title: 'Not found',
  msg_page_with_param_page_title: 'Page with param',
  msg_success_page_title: 'Success',

  msg_existing_users_table_application: 'Application',
  msg_existing_users_table_uuid: 'UUID',
  msg_existing_users_table_created_at: 'Created At',
  msg_existing_users_table_updated_at: 'Updated At',
  msg_existing_users_table_user_id: 'User ID',



  msg_field_date_from: 'From:',
  msg_field_date_to: 'To:',
  msg_btn_get_data: 'Get data',

  msg_field_client_id: 'App id',
  msg_field_client_token: 'App token',
  msg_field_widget: 'Demo Widget',
  msg_field_widget_open: 'Go to demo widget',
  msg_field_postback_url: 'Postback url',
  msg_field_webhook_url: 'Webhook url',
  msg_field_is_webhook_enabled: 'Is webhook enabled',

  msg_field_is_backfill_enabled: 'Is backfill enabled',
  msg_field_backfill_date_interval: "Backfill date interval",
  msg_field_backfill_settings: "Backfill settings",
  msg_field_backfill_date_interval_placeholder: "10",

  msg_validation_number_in_range_1_100: "Number must be between 1 and 100",
  msg_field_activities_summary: 'Activities summary',
  msg_field_activities_stream: 'Activities stream',
  msg_field_sleep: 'Sleep',
  msg_field_save: 'Save',
  msg_field_company_name: 'Company Name',

  msg_field_login: 'Login',
  msg_field_email: 'Email',
  msg_field_password: 'Password',

  msg_field_app_id: 'App id',
  msg_field_app_token: 'App token',
  msg_btn_save_token: 'Save token',

  msg_btn_logout_client: 'Logout client',
  msg_btn_logout_user: 'Logout user',
  msg_btn_reset_providers: 'Reset Providers',

  msg_metric_sleep: 'Sleep',
  msg_metric_heart: 'Heart',
  msg_metric_glucose: 'Glucose',
  msg_metric_activities_stream: 'Activities stream',
  msg_metric_activities_summary: 'Activities summary',
  msg_metric_calories: 'Calories',
  msg_metric_distance: 'Distance',
  msg_metric_steps: 'Steps',
  msg_metric_audio: 'Audio',
  msg_metric_podcasts: 'Podcasts',
  msg_metric_stress: 'Stress',
  msg_metric_goals: 'Goals',
  msg_metric_oxygen_saturation: 'Oxygen Saturation',
  msg_metric_breathing: 'Breathing',
  msg_metric_body: 'Body',
  msg_metric_info: 'Info',

  msg_label_integrations: 'Select Integrations:',
  msg_label_data_types: 'Select Data Type:',
  msg_label_select_date: 'Select Date:',

  msg_error_unexpected: 'Whoops, something went wrong.',

  msg_notification_updated: 'Changes saved',
} as const;