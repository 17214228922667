import { css } from '@emotion/react';
import React from 'react';

import { text } from '@/config/style';
import { useTranslation } from '@/hooks/useTranslation';

const NoData = () => {
  const { t } = useTranslation();

  return <span css={styles.noData}>{t('msg_common_no_data')}</span>;
};

export default NoData;

const styles = {
  noData: css`
    ${text.h4};
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: center;
  `,
};
