import { css } from '@emotion/react';
import React from 'react';

import { Breakpoint, ColorPalette, fontWeight, text } from '@/config/style';

type ButtonSize = 'medium' | 'big';

interface CardButtonProps {
  type?: 'submit' | 'button';
  children: React.ReactNode;
  size?: ButtonSize;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  active?: boolean;
}

const CardButton = ({ type = 'button', children, size = 'medium', onClick, disabled, active }: CardButtonProps) => (
  <button type={type} disabled={disabled} css={styles.button(active, size)} onClick={onClick}>
    <span css={styles.content}>{children}</span>
  </button>
);

export default CardButton;

const styles = {
  content: css`
    min-height: inherit;
    width: calc(100% - 20px);
    padding: 0 20px;
    left: 21px;

    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: flex-start;
    position: relative;

    background: white;
    border-radius: 0 6px 6px 0;

    ${Breakpoint.mobile} {
      padding: 0 15px;
    }
  `,

  button: (active?: boolean, size?: ButtonSize) => css`
    ${text.l};
    font-weight: ${fontWeight.bolderThanRegularButNotAsBoldAsSemiBold};

    padding: 0;
    border-radius: 6px;

    ${size === 'big' &&
    css`
      min-height: 50px;
      min-width: 230px;
    `}

    ${size === 'medium' &&
    css`
      min-width: 215px;
      min-height: 48px;
    `}
    
    ${Breakpoint.smallMobile} {
      width: 100%;
    }

    border: none;
    background-color: ${ColorPalette.blue_700};
    color: ${ColorPalette.blue_700};
    box-shadow: 0 4px 13px 0 rgba(0, 0, 0, 0.05);

    transition: 200ms all;
    cursor: pointer;

    ${active &&
    css`
      color: ${ColorPalette.blue_400};
      background-color: ${ColorPalette.blue_400};
    `}

    :not(:disabled) {
      :hover {
        color: ${ColorPalette.blue_400};
        background-color: ${ColorPalette.blue_400};
      }

      :active {
        color: ${ColorPalette.blue_500};
        background-color: ${ColorPalette.blue_500};
      }
    }

    :disabled {
      color: ${ColorPalette.grey_200};
      background-color: ${ColorPalette.grey_500};
      cursor: not-allowed;

      ${active &&
      css`
        color: ${ColorPalette.blue_disabled};
        background-color: ${ColorPalette.blue_disabled};
      `}
    }
  `,
};
