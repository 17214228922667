import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import LoadingSpinner from '@/components/LoadingSpinner';
import { LocalStorage } from '@/config/constants';
import { RouteConfig } from '@/config/routes';
import { getFromLocalStorage, setToLocalStorage } from '@/utils/localStorage';

const SuccessPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const userId = params.get('user_id');
    const latestAppId = getFromLocalStorage(LocalStorage.latestAppId);
    if (userId && latestAppId) {
      setToLocalStorage(`${latestAppId}_userId`, userId);
      navigate(RouteConfig.Widget.buildLink({ appId: latestAppId }));
      return;
    }
    navigate(RouteConfig.Home.buildLink());
  }, [location, navigate]);
  return <LoadingSpinner />;
};

export default SuccessPage;
