import { css } from '@emotion/react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import Button from '@/components/Buttons/Button';
import Input from '@/components/Form/Input';
import { LocalStorage } from '@/config/constants';
import { RouteConfig } from '@/config/routes';
import { useTranslation } from '@/hooks/useTranslation';
import { mapToInputProps } from '@/utils/form';
import { setToLocalStorage } from '@/utils/localStorage';
import { isUuidRule, requiredRule, uniqueRule, validateRules } from '@/utils/validation';
import ApiService from '@/services/api.service';
import { da } from 'date-fns/locale';

interface FormValues {
  email: string;
  password: string;
}

const apiService = ApiService.getInstance();
const AuthenticationTokenFormEmail = ({ defaultValues }: { defaultValues: FormValues }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState(null);
  const { handleSubmit, control, watch } = useForm<FormValues>({
    defaultValues,
  });

  return (
    <form
      css={styles.inputContainer}
      onSubmit={handleSubmit(async (data) => {
        setErrorMessage("")
        await apiService.login(data.email, data.password).then(res =>{
            if (res == true){
                navigate(RouteConfig.Application.buildLink());
            }else{
                setErrorMessage("Bad credentials")

            }
        })
      })}
    >
        {errorMessage && <div css={styles.errorMessage}>{errorMessage}</div>}
      
      <Controller
        control={control}
        name="email"
        rules={validateRules(requiredRule(t))}
        render={(controllerProps) => (
          <Input {...mapToInputProps(controllerProps)} placeholder={t('msg_field_email')} />
        )}
      />
      <Controller
        control={control}
        name="password"
        rules={validateRules(requiredRule(t))}
        render={(controllerProps) => (
          <Input {...mapToInputProps(controllerProps)} placeholder={t('msg_field_password')}
           inputType="password"
           />
        )}
      />
      <Button variant="secondary" size="medium" type="submit">
        {t('msg_field_login')}
      </Button>
    </form>
  );
};

export default AuthenticationTokenFormEmail;

const styles = {
  inputContainer: css`
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 500px;
    width: 100%;
  `,
  errorMessage: css`
    color: red;
    font-weight: bold;
    font-size: 1.2em;
    padding: 10px;
    background-color: rgba(255, 0, 0, 0.1); // Slight red background
    border-radius: 5px; // Rounded corners
    margin-bottom: 20px; // Some space to the next element
  `,
};
