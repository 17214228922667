import React from 'react';

export enum Align {
  left = 'flex-start',
  center = 'center',
  right = 'flex-end',
}

type ColumnBase = {
  id?: string;
  Header: string | React.ReactNode;
  width: number;
  maxWidth?: number;
  minHeight?: number;
  align?: Align;
  truncateText?: boolean;
  sortProperty?: string;
};

type ColumnWithOnlyCell<TableItem> = ColumnBase & {
  Cell: (item: TableItem) => React.ReactNode;
};

type ColumnWithOnlyAccessor<TableItem> = ColumnBase & {
  accessor: (item: TableItem) => string | number;
};

type ColumnWithAccessorAndCell<TableItem> = ColumnBase & {
  accessor: (item: TableItem) => string | number;
  Cell: (item: TableItem) => React.ReactNode;
};

export type Column<TableItem> =
  | ColumnWithOnlyAccessor<TableItem>
  | ColumnWithOnlyCell<TableItem>
  | ColumnWithAccessorAndCell<TableItem>;
