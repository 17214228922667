import React from 'react';
import { ControllerFieldState, FieldValues, FieldPath, FieldPathValue, UseFormStateReturn } from 'react-hook-form';
import { ControllerRenderProps } from 'react-hook-form/dist/types/controller';

import { FormWithGlobalErrorType } from '@/hooks/useFormWithGlobalError';
import { logError } from '@/utils/logger';
import { AnyObject } from '@/utils/types';

export const submitHandler = <T extends AnyObject>(
  form: FormWithGlobalErrorType<T>,
  submitFn: (values: T) => Promise<unknown> | undefined,
) => {
  return form.handleSubmit(async (values) => {
    form.setSubmitError(undefined);
    try {
      await submitFn(values);
    } catch (err) {
      // TODO: error handling
      logError(JSON.stringify(err));
    }
  });
};

export type InputPropsBase<Value> = {
  onChange?: (value: Value) => void;
  onBlur?: (e?: FocusEvent) => void;
  value: Value;
  name: string;
  inputRef?: React.Ref<HTMLInputElement>;
  invalid?: boolean;
  error?: string;
};

export const mapToInputProps = <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>({
  field,
  fieldState,
}: {
  field: ControllerRenderProps<TFieldValues, TName>;
  fieldState: ControllerFieldState;
  formState: UseFormStateReturn<TFieldValues>;
}): InputPropsBase<FieldPathValue<TFieldValues, TName>> => {
  return {
    onChange: field.onChange,
    onBlur: field.onBlur,
    value: field.value,
    name: field.name,
    inputRef: field.ref,
    invalid: !!fieldState.error,
    error: fieldState.error?.message,
  };
};
