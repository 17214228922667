import { css } from '@emotion/react';
import { differenceInDays, isAfter } from 'date-fns';
import React from 'react';
import { useForm, Controller } from 'react-hook-form';

import 'react-datepicker/dist/react-datepicker.css';
import Button from '@/components/Buttons/Button';
import DateInput from '@/components/Form/DateInput';
import { Breakpoint, fontWeight, text } from '@/config/style';
import { useTranslation } from '@/hooks/useTranslation';
import { mapToInputProps } from '@/utils/form';

interface FormValues {
  dateFrom: string | null;
  dateTo: string | null;
}

interface DateRangeFormProps {
  defaultValues: FormValues;
  onSubmit: (value: FormValues) => void;
}

const MAX_PERIOD_DAYS = 7;

const DateRangeForm = ({ defaultValues, onSubmit }: DateRangeFormProps) => {
  const { t } = useTranslation();
  const { handleSubmit, control, watch, formState } = useForm<FormValues>({
    defaultValues,
  });

  const today = new Date();
  const dateFrom = new Date(watch('dateFrom') ?? today);
  const dateTo = new Date(watch('dateTo') ?? today);
  const periodInDays = differenceInDays(dateTo, dateFrom);

  // NOTE: using custom error mechanism to React Hook Form's built in one - due to inconsistent and unreliable validation
  const missingStartDateError = !watch('dateFrom') ? t('msg_validation_required') : undefined;
  const missingEndDateError = !watch('dateTo') ? t('msg_validation_required') : undefined;
  const dateOrderError = isAfter(dateFrom, dateTo) ? t('msg_validation_start_date_after_end_date') : undefined;
  const dateRangeError =
    !!watch('dateTo') && periodInDays > MAX_PERIOD_DAYS
      ? t('msg_validation_max_day_period', { days: MAX_PERIOD_DAYS })
      : undefined;

  return (
    <form css={styles.container} onSubmit={handleSubmit(onSubmit)}>
      <div css={styles.inputContainer}>
        <label css={styles.label}>{t('msg_field_date_from')}</label>
        <Controller
          control={control}
          name="dateFrom"
          render={(controllerProps) => (
            <DateInput
              {...mapToInputProps(controllerProps)}
              error={missingStartDateError || dateOrderError}
              selectsStart
              startDate={dateFrom}
              endDate={dateTo}
              maxDate={today}
            />
          )}
        />
        <label css={styles.label}>{t('msg_field_date_to')}</label>
        <Controller
          control={control}
          name="dateTo"
          render={(controllerProps) => (
            <DateInput
              {...mapToInputProps(controllerProps)}
              error={dateRangeError || missingEndDateError}
              selectsEnd
              startDate={dateFrom}
              endDate={dateTo}
              minDate={dateFrom}
              maxDate={today}
            />
          )}
        />
        <div css={styles.button}>
          <Button
            variant="primary"
            size="medium"
            type="submit"
            disabled={!!dateRangeError || !!dateOrderError || !!missingStartDateError || !!missingEndDateError}
            loading={formState.isSubmitting}
          >
            {t('msg_btn_get_data')}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default DateRangeForm;

const styles = {
  container: css`
    margin-bottom: 10px;
    display: flex;
    gap: 10px;
  `,

  inputContainer: css`
    width: 100%;
    margin-bottom: 10px;

    display: grid;
    grid-template-columns: 1fr 4fr 1fr 4fr 4fr;
    gap: 15px;
    align-items: center;

    ${Breakpoint.tablet} {
      grid-template-columns: 1fr 4fr;
    }
  `,

  title: css`
    ${text.h4}
    padding-bottom: 10px;
  `,

  label: css`
    ${text.m};
    font-weight: ${fontWeight.semiBold};
  `,

  button: css`
    white-space: nowrap;

    ${Breakpoint.tablet} {
      grid-column-start: 2;
    }
  `,
};
