import { css } from '@emotion/react';
import React, { ReactNode, useState } from 'react';

import InputWrapper from '@/components/Form/InputWrapper';
import { inputStyles } from '@/config/style';
import { useInput, useInputProps } from '@/hooks/useInput';
import { InputPropsBase } from '@/utils/form';

type InputProps = {
  max?: number,
  min?: number,
  inputMode?: 'text' | 'tel' | 'email' | 'numeric';
  inputType?: 'text' | 'password' | 'number' ;
  maxLength?: number;
  outputTransformer?: (value: string) => string;
  placeholder?: string;
  prefix?: ReactNode;
} & InputPropsBase<string> &
  useInputProps;

const Input = (props: InputProps) => {
  const [isFocused, setIsFocused] = useState(false);

  const { inputProps, inputWrapperProps } = useInput(props);

  const {
    value,
    name,
    onChange = () => {},
    onBlur = () => {},
    inputRef,
    inputMode = 'text',
    inputType = 'text',
    outputTransformer = (val: string) => val,
    disabled,
    maxLength = 150,
    placeholder,
    error,
    readOnly,
    prefix,
    max,
    min
  } = props;

  return (
    <InputWrapper value={value} {...inputWrapperProps}>
      {prefix && <span css={styles.prefix}>{prefix}</span>}
      <input
        {...inputProps}
        css={inputStyles.input({ invalid: !!error, isFocused, readOnly, disabled, prefix: !!prefix })}
        onWheel={(e) => e.currentTarget.blur()}
        value={value || ''}
        onChange={(e) => {
          onChange(outputTransformer(e.target.value));
        }}
        onFocus={() => setIsFocused(true)}
        onBlur={(e) => {
          if (e.target.value !== e.target.value.trim()) {
            onChange(e.target.value.trim());
          }
          onBlur();
          setIsFocused(false);
        }}
        type={inputType}
        name={name}
        ref={inputRef}
        inputMode={inputMode}
        maxLength={maxLength}
        max={max}
        min={min}
        placeholder={placeholder}
      />
    </InputWrapper>
  );
};

export default Input;

const styles = {
  prefix: css`
    position: absolute;
    left: 8px;
    top: 12px;
  `,
};
