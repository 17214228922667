import { css } from '@emotion/react';
import { useCombobox } from 'downshift';
import React, { useMemo, useState } from 'react';

import InputWrapper from '@/components/Form/InputWrapper';
import { ColorPalette, inputStyles, text, zIndex } from '@/config/style';
import { InputSize, useInput, useInputProps } from '@/hooks/useInput';
import { useTranslation } from '@/hooks/useTranslation';
import { ReactComponent as ClearIcon } from '@/images/icons/close.svg';
import { ReactComponent as ExpandIcon } from '@/images/icons/dropdown-arrow.svg';
import { InputPropsBase } from '@/utils/form';
import { Option } from '@/utils/types';
import { noop } from '@/utils/util';

export type SelectProps<T> = {
  options: Option<T>[];
  clearable?: boolean;
  size?: InputSize;
} & InputPropsBase<T | null> &
  useInputProps;

const Select = <T,>(props: SelectProps<T>) => {
  const { t } = useTranslation();
  const [isFocused, setIsFocused] = useState(false);

  const { inputProps, inputWrapperProps } = useInput(props);

  const { options, value, onChange = noop, disabled, clearable, invalid, readOnly } = props;

  const {
    isOpen,
    toggleMenu,
    getInputProps,
    getMenuProps,
    getItemProps,
    highlightedIndex,
    inputValue,
    setInputValue,
    selectedItem,
  } = useCombobox({
    items: options,
    id: inputProps.id,
    selectedItem: options.find((option) => option.value === value) || null,
    itemToString: (item) => {
      return item?.label || '';
    },
    onSelectedItemChange: (item) => {
      if (item.selectedItem) {
        onChange(item.selectedItem.value);
        setIsFocused(false);
      }
    },
  });

  const visibleOptions = useMemo(() => {
    if (!inputValue || inputValue === selectedItem?.label) {
      return options;
    }

    return options.filter((option) => option.label.toLowerCase().includes(inputValue.toLowerCase()));
  }, [inputValue, options, selectedItem?.label]);

  return (
    <InputWrapper value={value} {...inputWrapperProps}>
      <div css={styles.selectWrapper}>
        <div css={styles.inputWrapper(props.size)}>
          <input
            {...inputProps}
            {...getInputProps({
              onFocus: () => {
                if (!isOpen) {
                  setIsFocused(true);
                  toggleMenu();
                }
              },
              onClick: () => {
                if (!isOpen) {
                  toggleMenu();
                }
              },
              onBlur: () => {
                if (isOpen) {
                  toggleMenu();
                  setIsFocused(false);
                  setInputValue(selectedItem?.label || '');
                }
              },
            })}
            placeholder={t('msg_select_default_placeholder')}
            css={[inputStyles.input({ invalid, isFocused, readOnly, disabled }), styles.input(clearable)]}
          />
          {clearable && !!value && !disabled && !readOnly && (
            <ClearIcon onClick={() => onChange(null)} css={styles.clearIcon} />
          )}
          {!disabled && !readOnly && <ExpandIcon css={styles.expandIcon} />}
        </div>
        <div {...getMenuProps()}>
          {isOpen && (
            <ul css={styles.selections}>
              {visibleOptions.map((item, index) => (
                <li
                  key={index}
                  {...getItemProps({
                    index,
                    item,
                  })}
                  css={[styles.selection, highlightedIndex === index ? { backgroundColor: ColorPalette.grey_100 } : {}]}
                >
                  <span>{item.label}</span>
                </li>
              ))}
              {visibleOptions.length === 0 && (
                <li css={[styles.selection]}>
                  <span css={{ color: ColorPalette.grey_100 }}>{t('msg_common_no_data')}</span>
                </li>
              )}
            </ul>
          )}
        </div>
      </div>
    </InputWrapper>
  );
};

export default Select;

const menuItemCount = 6;
const menuItemHeight = 40;

const styles = {
  selectWrapper: css`
    width: 100%;
  `,
  input: (clearable?: boolean) => css`
    padding-right: ${clearable ? 40 : 24}px;
    text-overflow: ellipsis;
  `,
  inputWrapper: (size?: InputSize) => css`
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;

    ${size === 'medium' &&
    css`
      height: 48px;
      min-height: 48px;
    `}

    ${size === 'small' &&
    css`
      height: 40px;
      min-height: 40px;
    `}
  `,
  expandIcon: css`
    position: absolute;
    right: 4px;
    min-width: 24px;
    height: 100%;
    fill: ${ColorPalette.blue_700};
    pointer-events: none;
  `,
  clearIcon: css`
    position: absolute;
    right: 20px;
    min-width: 16px;
    height: 100%;
    cursor: pointer;
    fill: ${ColorPalette.blue_700};
  `,
  selections: css`
    position: absolute;
    background-color: ${ColorPalette.white};
    z-index: ${zIndex.positive};
    width: 100%;
    margin: 0;
    box-shadow: 0 4px 13px 0 rgba(0, 0, 0, 0.05);
    list-style-type: none;
    max-height: calc(${menuItemHeight}px * ${menuItemCount});
    overflow-y: auto;
    text-align: left;
  `,
  selection: css`
    ${text.s};
    padding: 8px 16px;
    overflow-x: hidden;
    text-overflow: ellipsis;

    :hover {
      cursor: pointer;
    }
  `,
};
