import { useMemo } from 'react';

import { range } from '@/utils/util';

interface PaginationProps {
  totalRows: number;
  pageSize: number;
  siblingCount?: number;
  currentPage: number;
}

const DOTS = '...';

//@TODO: tests for usePagination hook
export const usePagination = ({ totalRows, pageSize, siblingCount = 1, currentPage }: PaginationProps) => {
  return useMemo(() => {
    const totalPageCount = Math.ceil(totalRows / pageSize);
    const totalPageNumbers = siblingCount + 5;

    const firstPageIndex = 0;
    const lastPageIndex = totalPageCount - 1;

    if (totalPageNumbers >= totalPageCount) {
      return range({ end: lastPageIndex });
    }

    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1) - 1;
    const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPageCount) - 1;

    const showLeftDots = leftSiblingIndex > 1;
    const showRightDots = rightSiblingIndex + 2 < lastPageIndex;

    if (!showLeftDots && showRightDots) {
      const leftItemCount = 3 + 2 * siblingCount;
      const leftRange = range({ start: 0, end: leftItemCount - 1 });

      return [...leftRange, DOTS, lastPageIndex];
    }

    if (showLeftDots && !showRightDots) {
      const rightItemCount = 3 + 2 * siblingCount;
      const rightRange = range({ start: totalPageCount - rightItemCount, end: lastPageIndex });

      return [firstPageIndex, DOTS, ...rightRange];
    }

    if (showLeftDots && showRightDots) {
      const middleRange = range({ start: leftSiblingIndex + 1, end: rightSiblingIndex + 1 });
      return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
    }
  }, [totalRows, pageSize, siblingCount, currentPage]);
};
