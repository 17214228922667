import { css } from '@emotion/react';
import { format } from 'date-fns';
import React, { useState } from 'react';
import DatePicker, {
  ReactDatePickerProps,
  ReactDatePickerCustomHeaderProps as CustomHeaderProps,
} from 'react-datepicker';

import Button from '@/components/Buttons/Button';
import { ColorPalette, fontWeight, inputStyles, text, zIndex } from '@/config/style';
import { useInput, useInputProps } from '@/hooks/useInput';
import { ReactComponent as ArrowLeftIcon } from '@/images/icons/arrow-left.svg';
import { ReactComponent as ArrowRightIcon } from '@/images/icons/arrow-right.svg';
import { ReactComponent as CalendarIcon } from '@/images/icons/calendar.svg';
import { dateFormat } from '@/utils/dateTransformations/dateTransformation';
import { InputPropsBase } from '@/utils/form';

import InputWrapper from './InputWrapper';

type DateInputProps = InputPropsBase<string | null> &
  useInputProps &
  Pick<ReactDatePickerProps, 'minDate' | 'maxDate' | 'selectsStart' | 'selectsEnd' | 'startDate' | 'endDate'>;

const renderCustomHeader = ({ date, increaseMonth, decreaseMonth }: CustomHeaderProps) => {
  return (
    <div css={styles.headerRow}>
      <Button size="icon" onClick={decreaseMonth}>
        <ArrowLeftIcon />
      </Button>

      <span css={text.l_bold}>{format(new Date(date), 'MMM, yyyy')}</span>

      <Button size="icon" onClick={increaseMonth}>
        <ArrowRightIcon />
      </Button>
    </div>
  );
};

const DateInput = ({
  value,
  onChange = () => {},
  minDate,
  maxDate,
  selectsStart,
  selectsEnd,
  startDate,
  endDate,
  error,
  disabled = false,
  ...rest
}: DateInputProps) => {
  const [open, setOpen] = useState(false);
  const { inputProps, inputWrapperProps } = useInput({ error, disabled, ...rest });

  return (
    <InputWrapper value={value} {...rest} {...inputWrapperProps}>
      <div css={[styles.container, styles.focusAccent]}>
        <DatePicker
          {...inputProps}
          open={open}
          onBlur={() => setOpen(false)}
          onFocus={() => setOpen(true)}
          onSelect={() => setOpen(false)}
          onClickOutside={() => setOpen(false)}
          css={[inputStyles.input({ invalid: !!error, disabled }), styles.focusAccent]}
          onChange={(date) => {
            if (!date) {
              return onChange(null);
            }
            onChange(format(date, dateFormat));
          }}
          selected={value ? new Date(value) : undefined}
          dateFormat={dateFormat}
          showPopperArrow={false}
          renderCustomHeader={renderCustomHeader}
          minDate={minDate}
          maxDate={maxDate}
          selectsStart={selectsStart}
          selectsEnd={selectsEnd}
          startDate={startDate}
          endDate={endDate}
        />
        {!disabled && <CalendarIcon onClick={() => setOpen(!open)} css={styles.calendarIcon} />}
      </div>
    </InputWrapper>
  );
};

export default DateInput;

const styles = {
  container: css`
    position: relative;
    height: 48px;
    width: 100%;

    .react-datepicker {
      margin-top: 10px;
      padding: 16px;
      border-radius: 6px;
      border: 0;
      box-shadow: 0 4px 13px 0 rgba(0, 0, 0, 0.05);
      background: ${ColorPalette.white};

      &-wrapper {
        height: inherit;
      }

      &__input-container {
        display: flex;
        height: inherit;
      }

      &__month {
        background: inherit;
      }

      &__header {
        background-color: ${ColorPalette.white};
        border-bottom: unset;
        border-radius: 6px;
      }

      &-popper {
        z-index: ${zIndex.dropdown};
        padding: 0;
      }

      &__day-name {
        color: ${ColorPalette.blue_700};
        font-weight: ${fontWeight.bold};
        width: 32px;
      }

      &__day {
        width: 30px;
        height: 30px;
        border-radius: 4px;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        &--keyboard-selected {
          background-color: inherit;
          color: inherit;
        }

        &--in-range {
          background-color: ${ColorPalette.blue_500};
        }

        &--weekend {
          &[aria-disabled='true'] {
            font-weight: unset;
            color: ${ColorPalette.grey_200};
          }
        }

        &--outside-month {
          color: ${ColorPalette.grey_200};
        }

        &--today {
          font-weight: normal;
        }

        &--selected {
          background-color: ${ColorPalette.blue_400};
          color: ${ColorPalette.white};
        }
      }
    }
  `,

  calendarIcon: css`
    position: relative;
    float: right;
    top: -37px;
    right: 20px;
    font-size: 24px;
    fill: ${ColorPalette.blue_700};

    &:hover {
      fill: ${ColorPalette.blue_400};
      cursor: pointer;
    }
  `,

  headerRow: css`
    display: flex;
    justify-content: space-between;
    margin: 0 8px;
    align-items: center;
  `,

  dateSelections: css`
    position: relative;
    display: grid;
    grid-template-columns: 2fr 3fr;
    gap: 8px;
  `,

  focusAccent: css`
    :not(:disabled):focus {
      color: ${ColorPalette.blue_400};
      fill: ${ColorPalette.blue_400};
    }
  `,
};
