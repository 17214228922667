import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { logError } from '@/utils/logger';
import EnTranslations from '@/utils/translations/en';
import { Translator } from '@/utils/translations/translationTypes';

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  lng: 'en',
  saveMissing: true,
  resources: {
    en: {
      translation: EnTranslations,
    },
  },
  missingKeyHandler: (_lngs, _ns, key) => {
    logError(`Missing translation key: ${key}`);
  },
  interpolation: {
    escapeValue: false,
  },
  missingInterpolationHandler: (key) => {
    logError(`Missing translation param: ${key}`);
  },
});

export type MessageKey = keyof typeof EnTranslations;

export const translate: Translator = i18n.t.bind(i18n);
