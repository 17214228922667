import { useQuery, useQueryClient, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { useEffect } from 'react';

import { useAppInfo } from '@/hooks/useAppInfo';

export const useAuthenticatedDataMeQuery = <TQueryFnData = unknown, TError = unknown, TData = TQueryFnData>(
  options: UseQueryOptions<TQueryFnData, TError, TData>,
): UseQueryResult<TData, TError> => {
  const appInfo = useAppInfo();
  const queryClient = useQueryClient();
// GET USER ID FROM JWT
// QUERY LIST OF APPLICATIONS @tomas

  useEffect(() => {
    if (!appInfo.appToken) {
      queryClient.resetQueries(options.queryKey);
    }
  }, [queryClient, options.queryKey, appInfo.appToken]);

  return useQuery({
    enabled: appInfo.appToken !== null,
    ...options,
  });
};
