import { css } from '@emotion/react';
import React from 'react';
import ReactJson from 'react-json-view';

import { LoadingSection } from '@/components/LoadingSpinner';
import NoData from '@/components/NoData';
import Endpoints from '@/config/endpoints';
import { Breakpoint, fontSize } from '@/config/style';
import { DataQueryParams, Metric, useDataDisplayQuery } from '@/services/app';

const DataPreview = ({ metric, params }: { metric: Metric; params: DataQueryParams }) => {
  const query = useDataDisplayQuery(Endpoints.METRICS(metric), params);

  if (query.isLoading) {
    return <LoadingSection />;
  }

  if (!query.data) {
    return <NoData />;
  }

  return (
    <div css={styles.dataDisplay}>
      <ReactJson src={query.data} displayDataTypes={false} />
    </div>
  );
};

export default DataPreview;

const styles = {
  dataDisplay: css`
    width: 100%;
    max-height: 550px;
    overflow: scroll;

    padding: 32px;
    ${Breakpoint.smallMobile} {
      font-size: ${fontSize['12']};
      padding: 10px;
    }
  `,
};
