import axios, { AxiosResponse } from 'axios';
// import jwt_decode from "jwt-decode";
import Config from '@/config/config';
import { LocalStorage } from '@/config/constants';
import { deleteFromLocalStorage } from '@/utils/localStorage';

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = "X-CSRFToken";
if (!Config.API_BASE_URL) {
    // eslint-disable-next-line no-console
    console.warn('API_URL is undefined');
  }
class ApiService {
  private static instance: ApiService;
  private token: string | null = localStorage.getItem("token");
  private refreshToken: string | null = localStorage.getItem("refreshToken");
//   if (!Config.API_BASE_URL) {
//     // eslint-disable-next-line no-console
//     console.warn('API_URL is undefined');
//   }
  private base_api_url: string = Config.DJANGO_BASE_URL
  private constructor() {}

  public static getInstance(): ApiService {
    if (!ApiService.instance) {
      ApiService.instance = new ApiService();
    }

    return ApiService.instance;
  }

  public async login(email: string, password: string): Promise<Boolean> {
    try {
      const response = await axios.post(this.base_api_url + "/api/users/login/", {
        email,
        password,
      });
      if (response.status === 201) {
        this.token = response.data.token;
        this.refreshToken = response.data.refresh;
        localStorage.setItem("token", this.token);
        localStorage.setItem("refreshToken", this.refreshToken);
        return true;
      }
      return false
    } catch (error) {
      return false
    }
  }

  public async refresh(): Promise<Boolean> {
    if (!this.refreshToken) {
      throw new Error("No refresh token available.");
    }

    try {
      const response = await axios.post(this.base_api_url + "/api/users/token/refresh/", {
        refresh: this.refreshToken,
      });

      if (response.status === 200) {
        this.token = response.data.access;

        localStorage.setItem("token", this.token);
        return true;
      }else{
        deleteFromLocalStorage(LocalStorage.token);
        deleteFromLocalStorage(LocalStorage.refreshToken);
        return false
        // this.navigate(RouteConfig.LoginEmail.buildLink());
      }
    } catch (error) {
      return false
    }
  }

  public request(endpoint: string): Promise<AxiosResponse> {
    return axios.get(this.base_api_url + `/api/${endpoint}`, {
      headers: {
        'Authorization': `Bearer ${this.token}`,
      },
    }).then(response => {
      // Resolve with the response if successful
      return response;
    }).catch(error => {
      // If there was an error, check the status
      if (error.response && error.response.status === 401) {
        // If it's a 401, try to refresh the token
        return this.refresh().then(ok => {
          if (ok) {
            // If the refresh was successful, repeat the request
            return this.request(endpoint);
          } else {
            // If the refresh failed, reject the promise with an error
            return Promise.reject(new Error("authorization issues"));
          }
        });
      } else {
        // If the error was something else, reject the promise with the original error
        return Promise.reject(error);
      }
    });
}
  private createFormData(jsonObject: object): FormData {
    const formData = new FormData();
  
    for (const [key, value] of Object.entries(jsonObject)) {
      formData.append(key, value);
    }
    
    return formData;
  }

  public async post(endpoint: string, bodydata: object): Promise<AxiosResponse> {
    
    var bodyFormData = this.createFormData(bodydata)
    try {

        const response = await axios({
            method: 'post',
            url: this.base_api_url + `/${endpoint}`,
            data: bodyFormData,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Authorization: `Bearer ${this.token}`,
            },
        });
      
      return response;
    } catch (error) {
      if (error.response.status === 401) {
        await this.refresh();

        // Repeat the request with the new token
        return await this.post(endpoint, bodydata);
      } else {
        throw error;
      }
    }
  }
//   public async post(endpoint: string): Promise<AxiosResponse> {
}

export default ApiService;