import axios from 'axios';

import Config from '@/config/config';
import { LocalStorage } from '@/config/constants';
import { getFromLocalStorage } from '@/utils/localStorage';

if (!Config.API_BASE_URL) {
  // eslint-disable-next-line no-console
  console.warn('API_URL is undefined');
}

const authApiInstance = axios.create({
  baseURL: Config.API_BASE_URL,
});

const buildAuthHeader = (token: string) => `${token}`;

authApiInstance.interceptors.request.use(
  (config) => {
    (config.headers as Record<string, string>)['x-spike-auth'] = buildAuthHeader(
      getFromLocalStorage(LocalStorage.appToken) || '',
    );

    return config;
  },
  (err) => {
    return Promise.reject(err);
  },
);

export default authApiInstance;
