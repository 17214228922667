import { css } from '@emotion/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '@/components/Buttons/Button';
import { LocalStorage } from '@/config/constants';
import { RouteConfig } from '@/config/routes';
import { Breakpoint, ColorPalette, text } from '@/config/style';
import { useTranslation } from '@/hooks/useTranslation';
import { ReactComponent as BurgerMenu } from '@/images/icons/burger.svg';
import { ReactComponent as LogOutIcon } from '@/images/icons/logout.svg';
import { ReactComponent as Logo } from '@/images/icons/spike-logo.svg';
import { deleteFromLocalStorage } from '@/utils/localStorage';

export const DESKTOP_HEADER_HEIGHT = 95;
export const MOBILE_HEADER_HEIGHT = 135;

const handleLogout = () => {
  deleteFromLocalStorage(LocalStorage.token);
  deleteFromLocalStorage(LocalStorage.refreshToken);
};

const MobileHeader = ({ onSidebarToggle }: { onSidebarToggle: () => void }) => {
  const navigate = useNavigate();

  return (
    <div css={styles.mobileHeader}>
      <div css={styles.mobileHeaderTopRow}>
        <Button size="icon" variant="transparent" onClick={() => onSidebarToggle()}>
          <BurgerMenu style={{ fontSize: '24px' }} />
        </Button>
        <Logo style={{ fontSize: '10em' }} />
        <Button
          variant="outlined"
          size="icon"
          onClick={() => {
            handleLogout();
            navigate(RouteConfig.LoginEmail.buildLink());
          }}
        >
          <LogOutIcon />
        </Button>
        {/*TODO: replace with page title*/}
      </div>
      <div css={styles.mobileHeaderBottomRow}>
        <h3 css={text.h3}>Developer Console</h3>
      </div>
    </div>
  );
};

const DesktopHeader = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div css={styles.desktopHeader}>
      {/*TODO: replace with page title*/}
      <h3 css={text.h3}>Developer Console</h3>
      <div css={styles.rightNode}>
        {/*TODO: add current user email*/}
        {/*<span css={styles.welcome}>*/}
        {/*  <span>Welcome, {email}</span>*/}
        {/*</span>*/}
        <div css={styles.logoutWrapper}>
          <Button
            onClick={() => {
              handleLogout();
              navigate(RouteConfig.LoginEmail.buildLink());
            }}
          >
            {t('msg_common_log_out')}
          </Button>
        </div>
      </div>
    </div>
  );
};

const Header = ({ onSidebarToggle }: { onSidebarToggle: () => void }) => {
  return (
    <div css={styles.container}>
      <DesktopHeader />
      <MobileHeader onSidebarToggle={onSidebarToggle} />
    </div>
  );
};

export default Header;

const styles = {
  container: css`
    height: ${DESKTOP_HEADER_HEIGHT}px;
    max-height: ${DESKTOP_HEADER_HEIGHT}px;
    background-color: ${ColorPalette.white};

    ${Breakpoint.mobile} {
      height: ${MOBILE_HEADER_HEIGHT}px;
      max-height: ${MOBILE_HEADER_HEIGHT}px;
    }
  `,

  desktopHeader: css`
    height: inherit;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 32px;

    ${Breakpoint.mobile} {
      display: none;
    }
  `,

  rightNode: css`
    display: flex;
    align-items: center;
    gap: 16px;
  `,

  welcome: css`
    ${Breakpoint.mobile} {
      display: none;
    }
  `,

  logoutWrapper: css`
    width: 130px;
  `,

  mobileHeader: css`
    display: none;
    padding: 0 20px;

    ${Breakpoint.mobile} {
      display: flex;
      flex-direction: column;
      height: inherit;
    }
  `,

  mobileHeaderTopRow: css`
    display: none;

    ${Breakpoint.mobile} {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-height: 70px;
    }
  `,

  mobileHeaderBottomRow: css`
    display: none;

    ${Breakpoint.mobile} {
      display: flex;
      align-items: center;
      height: 100%;
    }
  `,
};
