import { Metric } from '@/services/app';

const endpoints = {
  INIT_AUTH: '/init-user-integration/',
  METRICS: (metric: Metric) => `v2/metrics/${metric}`,
  USER: (id: string) => `users/${id}`,
  RESET_INTEGRATIONS: (id: string) => `users/${id}/remove-integrations`,
  BACKFILL_SETTINGS: "/v2/backfill/settings"
};

export default endpoints;
