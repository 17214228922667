import { LocalStorage } from '@/config/constants';
import { getFromLocalStorage, setToLocalStorage } from '@/utils/localStorage';

export const noop = () => {};

//@TODO: tests for range fn
export const range = ({ start = 0, end }: { start?: number; end: number }) => {
  const length = end - start + 1;
  return Array.from({ length }, (_, idx) => idx + start);
};

export const getRandomInt = (min: number, max: number) => Math.floor(Math.random() * (max - min)) + min;

export const generateRandomId = (length: number) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};

export const getUserId = () => {
  const userId = getFromLocalStorage(LocalStorage.userId);
  if (!userId) {
    const randomId = generateRandomId(10);
    setToLocalStorage(LocalStorage.userId, randomId);
    return randomId;
  }
  return userId;
};
