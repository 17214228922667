import { css } from '@emotion/react';
import React from 'react';

import { ColorPalette, fontWeight, text } from '@/config/style';
import { ReactComponent as DropdownArrow } from '@/images/icons/dropdown-arrow.svg';
import { noop, range } from '@/utils/util';

import { EmptyRow, Row, SkeletonCell } from './Row';
import { Align, Column } from './types';

export type TableProps<TableItem extends object> = {
  columns: Column<TableItem>[];
  data: TableItem[] | undefined;
  loading: boolean;
  currentSort?: { property: string; direction: SortDirection };
  onSortChange?: (newSort: { property: string; direction: SortDirection }) => void;
};

export type SortDirection = 'asc' | 'desc';

const LOADING_ITEMS_COUNT = 3;

const Table = <TableItem extends object>({
  columns,
  data,
  loading = false,
  currentSort,
  onSortChange = noop,
}: TableProps<TableItem>) => {
  const renderTableBody = () => {
    if (loading) {
      return (
        <>
          {range({ end: LOADING_ITEMS_COUNT }).map((index) => (
            <Row key={index} index={index} columns={columns} content={() => <SkeletonCell />} />
          ))}
        </>
      );
    }

    if (!data?.length) {
      return <EmptyRow />;
    }

    return data.map((item, index) => (
      <Row
        key={index}
        index={index}
        columns={columns}
        content={(column) => {
          return (
            <div css={column.truncateText && styles.truncateText}>
              {'Cell' in column ? column.Cell(item) : column.accessor(item)}
            </div>
          );
        }}
      />
    ));
  };

  const renderSortedIcon = (column: Column<TableItem>) => {
    if (!currentSort || column.sortProperty !== currentSort.property) {
      return null;
    }

    return (
      <DropdownArrow
        width={24}
        height={24}
        fill={ColorPalette.blue_700}
        css={styles.sortArrow({ direction: currentSort.direction })}
      />
    );
  };

  return (
    <div css={styles.wrapper}>
      <table css={styles.table}>
        <thead>
          <tr css={styles.tableHeader}>
            {columns.map((column, index) => {
              const { sortProperty } = column;

              return (
                <th
                  key={index}
                  css={styles.tableHeaderCell({
                    align: column.align,
                    width: column.width,
                    maxWidth: column.maxWidth,
                  })}
                >
                  {sortProperty ? (
                    <button
                      type="button"
                      css={styles.columnHeadingContent(!!sortProperty)}
                      onClick={() => {
                        onSortChange({
                          direction:
                            !currentSort ||
                            column.sortProperty !== currentSort.property ||
                            currentSort.direction === 'desc'
                              ? 'asc'
                              : 'desc',
                          property: sortProperty,
                        });
                      }}
                    >
                      <h3 css={styles.heading}>{column.Header}</h3>
                      {renderSortedIcon(column)}
                    </button>
                  ) : (
                    <div css={styles.columnHeadingContent(!!sortProperty)}>
                      <h3 css={styles.heading}>{column.Header}</h3>
                    </div>
                  )}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody css={styles.tableBody}>{renderTableBody()}</tbody>
      </table>
    </div>
  );
};

export default Table;

const styles = {
  wrapper: css`
    width: 100%;
    white-space: nowrap;
  `,
  table: css`
    overflow-x: auto;
    display: flex;
    flex-direction: column;
  `,
  tableHeader: css`
    min-width: fit-content;
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${ColorPalette.blue_700};
  `,
  tableHeaderCell: ({ align, width, maxWidth }: { align?: Align; width: number; maxWidth?: number }) => css`
    flex: ${width} 0 auto;
    width: ${width}px;

    ${maxWidth &&
    css`
      max-width: ${width}px;
    `}

    min-width: 0;
    min-height: 30px;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    word-break: break-word;
    justify-content: ${align};

    ${align === Align.right &&
    css`
      text-align: right;
    `}
  `,
  columnHeadingContent: (sortable?: boolean) => css`
    ${text.m}
    font-weight: ${fontWeight.semiBold};

    height: 24px;
    display: flex;
    gap: 10px;
    border: none;
    background-color: unset;
    padding: 0;
    align-items: center;

    ${sortable
      ? css`
          cursor: pointer;
        `
      : css`
          user-select: none;
        `}
  `,
  heading: css`
    white-space: pre-wrap;
    overflow: hidden;
    margin: 0;
    color: ${ColorPalette.grey_400};
  `,
  tableBody: css`
    border-radius: 6px;
    margin-top: 8px;
    min-width: fit-content;
  `,
  sortIcon: css`
    min-width: 16px;
  `,
  sortArrow: ({ direction }: { direction: SortDirection }) => css`
    transform: rotate(${direction === 'asc' ? 0 : 180}deg);
    transition: transform 200ms;
    min-width: 16px;
  `,
  truncateText: css`
    padding-right: 4px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  `,
};
