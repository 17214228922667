import { css, Global } from '@emotion/react';
import * as Sentry from '@sentry/browser';
import React from 'react';
import ReactDOM from 'react-dom/client';

import '@/utils/i18n';

import App from '@/components/App';
import Config from '@/config/config';
import { ColorPalette, fontFamily } from '@/config/style';

if (Config.SENTRY_DSN) {
  Sentry.init({ dsn: Config.SENTRY_DSN, environment: Config.SENTRY_ENVIRONMENT });
}

const globalStyles = css`
  html {
    height: 100%;
    font-size: 16px;
    font-family: ${fontFamily.poppins};
  }

  body,
  #root {
    height: 100%;
  }

  a {
    color: ${ColorPalette.blue_700};
  }

  a,
  a:link,
  a:visited,
  a:focus,
  a:hover,
  a:active {
    text-decoration: none;
  }
`;

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <>
    <Global styles={globalStyles} />
    <App />
  </>,
);
