import { css } from '@emotion/react';

export const ColorPalette = {
  blue_700: '#1E2835',
  blue_500: '#2C5098',
  blue_disabled: 'rgba(74, 131, 247, 0.65)',
  blue_400: '#4A83F7',
  blue_300: '#6B9CFF',
  blue_100: '#D1E0FF',
  blue_50: '#EDF3FF',

  grey_500: 'rgba(30, 40, 53, 0.65)',
  grey_400: '#2E3C4E',
  grey_300: '#566170',
  grey_200: '#AAA',
  grey_100: '#EBEBEB',

  white: '#FFF',
  white_100: '#F5F5F5',
  error: '#dd1717',
} as const;

export type Color = keyof typeof ColorPalette;

export const lineHeight = {
  18: '1.125rem',
  21: '1.3125rem',
  24: '1.5rem',
  27: '1.6875rem',
  30: '1.875rem',
  54: '3.375rem',
  72: '4.5rem',
} as const;

export const fontWeight = {
  regular: 400,
  bolderThanRegularButNotAsBoldAsSemiBold: 500,
  semiBold: 600,
  bold: 700,
} as const;

export const fontSize = {
  12: '0.75rem',
  14: '0.875rem',
  16: '1rem',
  18: '1.125rem',
  20: '1.25rem',
  32: '2rem',
  36: '2.25rem',
  40: '2.5rem',
  48: '3rem',
} as const;

export const fontFamily = {
  poppins: 'Poppins, sans-serif',
} as const;

export const SmallMobileBreakpoint = 480;
export const MobileBreakpoint = 768;
export const TabletBreakpoint = 992;
export const DesktopBreakpoint = 1280;
export const BigDesktopBreakpoint = 1366;

export const Breakpoint = {
  smallMobile: `@media(max-width: ${SmallMobileBreakpoint}px)`,
  mobile: `@media(max-width: ${MobileBreakpoint}px)`,
  tablet: `@media(max-width: ${TabletBreakpoint}px)`,
  desktop: `@media(max-width: ${DesktopBreakpoint}px)`,
  bigDesktop: `@media(max-width: ${BigDesktopBreakpoint}px)`,
};

export const zIndex = {
  positive: 1,
  negative: -1,
  header: 2,
  dropdown: 3,
  modalBackdrop: 4,
  sidebar: 5,
  modal: 6,
  loadingOverlay: 7,
} as const;

type InputOptions = {
  invalid?: boolean;
  isFocused?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  prefix?: boolean;
};

export const text = {
  h1: css`
    font-weight: ${fontWeight.bold};
    font-size: ${fontSize['48']};
    line-height: ${lineHeight['72']};
  `,
  h2: css`
    font-weight: ${fontWeight.bold};
    font-size: ${fontSize['36']};
    line-height: ${lineHeight['54']};
  `,
  h3: css`
    font-weight: ${fontWeight.bold};
    font-size: ${fontSize['20']};
    line-height: ${lineHeight['30']};
  `,
  h4: css`
    font-weight: ${fontWeight.semiBold};
    font-size: ${fontSize['18']};
    line-height: ${lineHeight['27']};
  `,
  l: css`
    font-weight: ${fontWeight.regular};
    font-size: ${fontSize['16']};
    line-height: ${lineHeight['24']};
  `,
  l_bold: css`
    font-weight: ${fontWeight.bold};
    font-size: ${fontSize['16']};
    line-height: ${lineHeight['24']};
  `,
  m: css`
    font-weight: ${fontWeight.regular};
    font-size: ${fontSize['14']};
    line-height: ${lineHeight['21']};
  `,
  m_bold: css`
    font-weight: ${fontWeight.bold};
    font-size: ${fontSize['14']};
    line-height: ${lineHeight['21']};
  `,
  s: css`
    font-weight: ${fontWeight.regular};
    font-size: ${fontSize['12']};
    line-height: ${lineHeight['18']};
  `,
  s_bold: css`
    font-weight: ${fontWeight.bold};
    font-size: ${fontSize['12']};
    line-height: ${lineHeight['18']};
  `,
};

export const helperStyles = {
  paper: css`
    width: 100%;
    background-color: white;
    padding: 30px;
    border-radius: 6px;
    box-shadow: 0 4px 13px rgba(0, 0, 0, 0.04);

    ${Breakpoint.mobile} {
      padding: 20px;
    }
  `,
};

export const inputStyles = {
  input: (options: InputOptions) => css`
    ${text.l}

    background: ${ColorPalette.white};
    -moz-appearance: textfield;
    flex: 1;
    transition: all 0.1s linear;
    width: 100%;
    padding: 16px 24px;

    color: ${ColorPalette.blue_700};
    box-shadow: 0 4px 13px rgba(0, 0, 0, 0.05);
    border: 1px solid ${ColorPalette.grey_100};
    border-radius: 6px;

    :focus {
      outline: none;
    }

    ::placeholder {
      color: ${ColorPalette.grey_200};
    }

    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    ${options.isFocused &&
    !options.invalid &&
    css`
      border-color: ${ColorPalette.blue_700};
    `}

    :-webkit-autofill {
      -webkit-box-shadow: 0 0 0 30px ${ColorPalette.white} inset;
      box-shadow: 0 0 0 30px ${ColorPalette.white} inset;
    }

    ${options.invalid &&
    css`
      border-color: ${ColorPalette.error};
    `}

    ${options.readOnly &&
    css`
      border: none;
      border-radius: 0;
      border-top: 1px solid ${ColorPalette.grey_100};
      background-color: ${ColorPalette.white};
      padding: 0;
      pointer-events: none;
    `}

    ${options.disabled &&
    css`
      background-color: ${ColorPalette.grey_100};
    `}

    ${options.prefix &&
    css`
      padding-left: 32px;
    `}
  `,
};
