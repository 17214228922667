import { css } from '@emotion/react';
import React, { ReactNode, useState } from 'react';

import LoadingSpinner, { LoadingSection } from '@/components/LoadingSpinner';
import { ColorPalette, text } from '@/config/style';
import { ReactComponent as ActiveCircle } from '@/images/icons/active-circle.svg';
import { ReactComponent as DisabledCircle } from '@/images/icons/disabled-circle.svg';
import { ReactComponent as KeyboardArrowDownIcon } from '@/images/icons/dropdown-arrow.svg';
import { ReactComponent as PendingCircle } from '@/images/icons/pending-circle.svg';

interface SectionProps {
  title: string;
  loading?: boolean;
  children: ReactNode;
  active?: boolean;
  disabled?: boolean;
  dashed?: boolean;
  initiallyExpanded?: boolean;
}

const CIRCLE_SIZE = 36;

const StatusCircle = ({ active, disabled }: { active?: boolean; disabled?: boolean }) => {
  const size = { width: CIRCLE_SIZE, height: CIRCLE_SIZE };

  if (disabled) {
    return <DisabledCircle {...size} />;
  }

  if (active) {
    return <ActiveCircle {...size} />;
  }

  return <PendingCircle {...size} />;
};

const Section = ({
  title,
  loading,
  children,
  disabled,
  active,
  dashed = true,
  initiallyExpanded = false,
}: SectionProps) => {
  const [expanded, setExpanded] = useState(initiallyExpanded);

  return (
    <div>
      <h4
        css={styles.title({ disabled: loading || disabled, active })}
        onClick={() => {
          if (loading || disabled) {
            return;
          }
          setExpanded(!expanded);
        }}
      >
        <StatusCircle active={active} disabled={disabled} />
        {title}
        {loading && !expanded && <LoadingSpinner size={24} />}
        <KeyboardArrowDownIcon width={24} height={24} css={styles.expandIcon({ expanded })} />
      </h4>
      {expanded && !disabled && (
        <div css={styles.wrapper(dashed)}>
          {dashed && (
            <div css={styles.dashWrapper}>
              <div css={styles.dashes(active)} />
            </div>
          )}
          <div css={styles.body}>{loading ? <LoadingSection /> : children}</div>
        </div>
      )}
    </div>
  );
};

export default Section;

const styles = {
  title: ({ disabled, active }: { disabled?: boolean; active?: boolean }) => css`
    ${text.h4};
    display: flex;
    gap: 10px;
    cursor: pointer;
    align-items: center;
    fill: ${ColorPalette.blue_700};

    ${active &&
    css`
      fill: ${ColorPalette.blue_400};
      color: ${ColorPalette.blue_400};
    `}

    ${disabled &&
    css`
      cursor: not-allowed;
      fill: ${ColorPalette.grey_200};
      color: ${ColorPalette.grey_200};
    `}
  `,

  expandIcon: ({ expanded }: { expanded: boolean }) => css`
    transform: rotate(${expanded ? 0 : -90}deg);
    transition: transform 200ms;
    margin-left: auto;
  `,

  wrapper: (dashed?: boolean) => css`
    display: grid;
    gap: 10px;
    grid-template-columns: auto;

    ${dashed &&
    css`
      grid-template-columns: ${CIRCLE_SIZE}px auto;
    `}
  `,

  dashWrapper: css`
    height: calc(100% + 10px);
    display: flex;
    justify-content: center;
    width: ${CIRCLE_SIZE}px;
  `,

  dashes: (active?: boolean) => css`
    border-left: 1px dashed ${active ? ColorPalette.blue_400 : ColorPalette.blue_700};
  `,

  body: css`
    display: grid;
    grid-template-columns: 1fr;
    align-items: flex-start;
    gap: 24px;
    padding: 10px;
  `,
};
