import { css } from '@emotion/react';
import React, { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { Control, Controller, useForm } from 'react-hook-form';
import { useRouteParams } from '@/hooks/useRouteParams';
import { RouteConfig } from '@/config/routes';
import { useTranslation } from '@/hooks/useTranslation';
import Button from '@/components/Buttons/Button';
import Input from '@/components/Form/Input';
import { mapToInputProps } from '@/utils/form';
import { setToLocalStorage } from '@/utils/localStorage';
import { LocalStorage } from '@/config/constants';
import ApiService from '@/services/api.service';
import { deleteFromLocalStorage } from '@/utils/localStorage';
import { text } from '@/config/style';
import endpoints from '@/config/endpoints';
import { getBackfillSettings, updateBackfillSettings } from '@/services/app';

interface BackfillSettings {
  is_enabled: boolean
  interval_days: number
  // NOTE: for a proper solution could use the array field type
  data_types: {
    activities_summary: boolean
    activities_stream: boolean 
    sleep: boolean
  }
}

interface FormValues {
  company_name: string;
  email: string | undefined;
  client_id: string | undefined;
  client_token: string | undefined;
  created_at: string | undefined;
  updated_at: string | undefined;
  user_id: string | undefined;
  is_v2_enabled: boolean,
  is_webhook_enabled: boolean,
  postback_url: string | undefined;
  webhook_url: string | undefined;
  backfill_settings: BackfillSettings
}

const apiService = ApiService.getInstance()
const ApplicationEditPage = () => {
  const { clientId } = useRouteParams(RouteConfig.ApplicationEdit);

  const { t } = useTranslation();
  const navigate = useNavigate();

  let timeoutId = null;
  const [showNotification, setShowNotification] = useState(false);

  const { handleSubmit, control, setValue, trigger } = useForm<FormValues>({
    defaultValues: {
      company_name: '',
      email: '',
      client_id: '',
      client_token: '',
      created_at: '',
      updated_at: '',
      user_id: '',
      is_v2_enabled: false,
      is_webhook_enabled: false,
      postback_url: '',
      webhook_url: '',
      backfill_settings: {
        interval_days: 10,
        data_types: {
          sleep: false,
          activities_stream: false,
          activities_summary: false
        }
      }
    }
  });

  const getApp = async () => {
    const clientResult = await apiService.request('users/client/')

    for (let app of clientResult.data.results) {
      if (app.client_id === clientId) {
        return app;
      }
    }
  }

  const getSettings = async () => {
    try {
      const result = await getBackfillSettings(endpoints.BACKFILL_SETTINGS)
      return result.data.data;
    } catch (err) {
      return null;
    }
  }

  const setInitialFormValues = async () => {
    try {
      const app = await getApp();
      const backfillSettings = await getSettings()

      setValue('company_name', app.company_name)
      setValue('client_id', app.client_id)
      setValue('client_token', app.client_token)
      setValue('webhook_url', app.webhook_url)
      setValue('is_webhook_enabled', app.is_webhook_enabled)
      setValue('postback_url', app.postback_url)
      setValue('is_v2_enabled', app.is_v2_enabled)

      if (backfillSettings) {
        setValue('backfill_settings.is_enabled', backfillSettings.is_enabled)
        setValue('backfill_settings.interval_days', backfillSettings.interval_days)
        
        for (let data_type of backfillSettings.data_types || []) {
          // @ts-ignore dynamic formation for all existing data types
          setValue(`backfill_settings.data_types.${data_type}`, true);
        }
      }

      setToLocalStorage(LocalStorage.appId, app.client_id);
      setToLocalStorage(LocalStorage.appToken, app.client_token);
      trigger(); // re-render the component
    } catch (err) {
      deleteFromLocalStorage(LocalStorage.token);
      deleteFromLocalStorage(LocalStorage.refreshToken);

      navigate(RouteConfig.LoginEmail.buildLink());
    }
  }

  useEffect(() => {
    () => {
      // Cleanup timer when component unmounts
      clearTimeout(timeoutId);
    }
    setInitialFormValues();
  }, [setValue, trigger]);

  return (
    <form
      css={styles.inputContainer}
      onSubmit={handleSubmit(async (data) => {
        const backfillSettings = data.backfill_settings;
        const {backfill_settings:_, ...restOfData} = data;

        const result = apiService.post('api/users/client/' + data.client_id + '/', restOfData)
        await updateBackfillSettings(backfillSettings)

        setShowNotification(true);
        timeoutId = setTimeout(() => {
          setShowNotification(false);
        }, 3000);
      })
      }
    >
      <label htmlFor="company_name">{t('msg_field_company_name')}</label>
      <Controller
        control={control}
        name="company_name"
        // rules={validateRules(requiredRule(t), uniqueRule(t, watch('email')), isUuidRule(t))}
        render={(controllerProps) => (
          <Input {...mapToInputProps(controllerProps)} placeholder={t('msg_field_company_name')} disabled
          />
        )}
      />
      <label htmlFor="client_id">{t('msg_field_client_id')}</label>
      <Controller
        control={control}
        name="client_id"
        // rules={validateRules(requiredRule(t), uniqueRule(t, watch('email')), isUuidRule(t))}
        render={(controllerProps) => (
          <Input {...mapToInputProps(controllerProps)} placeholder={t('msg_field_client_id')} disabled />
        )}
      />
      <label htmlFor="client_token">{t('msg_field_client_token')}</label>
      <Controller
        control={control}
        name="client_token"
        // rules={validateRules(requiredRule(t), uniqueRule(t, watch('email')), isUuidRule(t))}
        render={(controllerProps) => (
          <Input {...mapToInputProps(controllerProps)} placeholder={t('msg_field_client_token')} disabled />
        )}
      />
      <label htmlFor="widget">{ }</label>
      <Controller
        control={control}
        name="widget"
        // rules={validateRules(requiredRule(t), uniqueRule(t, watch('email')), isUuidRule(t))}
        render={(controllerProps) => (

          <Button variant="secondary" size="medium" onClick={() => {
            navigate(RouteConfig.Widget.buildLink({ appId: clientId }))
          }}>
            {t('msg_field_widget_open')}
          </Button>
          //   <Input {...mapToInputProps(controllerProps)} placeholder={t('msg_field_client_token')} disabled />
        )}
      />
      <label htmlFor="postback_url">{t('msg_field_postback_url')}</label>
      <Controller
        control={control}
        name="postback_url"
        // rules={validateRules(requiredRule(t), uniqueRule(t, watch('email')), isUuidRule(t))}
        render={(controllerProps) => (
          <Input {...mapToInputProps(controllerProps)} placeholder={t('msg_field_postback_url')} />
        )}
      />
      <label htmlFor="webhook_url">{t('msg_field_webhook_url')}</label>
      <Controller
        control={control}
        name="webhook_url"
        // rules={validateRules(requiredRule(t), uniqueRule(t, watch('email')), isUuidRule(t))}
        render={(controllerProps) => (
          <Input {...mapToInputProps(controllerProps)} placeholder={t('msg_field_webhook_url')} />
        )}
      />
      <div css={styles.labelWithCheckbox}>
        <label htmlFor="is_webhook_enabled">{t('msg_field_is_webhook_enabled')}</label>
        <Controller
          control={control}
          name="is_webhook_enabled"
          render={(controllerProps) => (
            <input
              {...mapToInputProps(controllerProps)}
              type="checkbox"
              css={styles.checkbox}
              checked={mapToInputProps(controllerProps).value}
            />
          )}
        />
      </div>

      <h1 css={styles.backfillTitle}>{t('msg_field_backfill_settings')}</h1>
      <div css={styles.labelWithCheckbox}>
        <label htmlFor="backfill_settings.is_enabled">{t('msg_field_is_backfill_enabled')}</label>
        <Controller
          control={control}
          name="backfill_settings.is_enabled"
          render={(controllerProps) => (
            <input
              {...mapToInputProps(controllerProps)}
              type="checkbox"
              css={styles.checkbox}
              checked={mapToInputProps(controllerProps).value}
            />
          )}
        />
      </div>
      <div css={styles.inputContainer}>
        <label htmlFor="backfill_settings.interval_days">{t('msg_field_backfill_date_interval')}</label>
        <Controller
          control={control}
          name="backfill_settings.interval_days"
          render={(controllerProps) => (
            <Input max={100} min={1} inputType='number' inputMode='numeric' {...mapToInputProps(controllerProps)} placeholder={t('msg_field_backfill_date_interval_placeholder')} />
          )}
        />
      </div>
      <BackfillDataTypes control={control} />


      <Button variant="secondary" size="medium" type="submit">
        {t('msg_field_save')}
      </Button>
      {showNotification && (
        <div css={styles.notification}>
          {t('msg_notification_updated')}
        </div>
      )}
    </form>
  );
};

const BackfillDataTypes = ({ control }: { control: Control<FormValues> }) => {
  const DATA_TYPES = [
    "activities_summary", "activities_stream", "sleep"
  ]

  const renderDataTypes = () => {
    return DATA_TYPES.map((dataType) => (
      <BackfillDataType
        key={dataType}
        control={control}
        dataType={dataType}
      />
    ))
  }

  return renderDataTypes();
}

interface BackfillDataTypeProps {
  dataType: string
  control: Control<FormValues>
}

const BackfillDataType = ({ control, dataType }: BackfillDataTypeProps) => {
  const { t } = useTranslation();
  const labelName = `backfill_settings.data_types.${dataType}`

  return (
    <div css={styles.labelWithCheckbox}>
      <label htmlFor={labelName}>{t(`msg_field_${dataType}`)}</label>
      <Controller
        control={control}
        name={labelName}
        render={(controllerProps) => (
          <input
            {...mapToInputProps(controllerProps)}
            type="checkbox"
            css={styles.backfillCheckbox}
            checked={mapToInputProps(controllerProps).value}
          />
        )}
      />
    </div>
  )
}

export default ApplicationEditPage;


const styles = {
  inputContainer: css`
      display: flex;
      flex-direction: column;
      gap: 20px;
      max-width: 500px;
      width: 100%;
    `,
  notification: css`
        color: #155724;
        background-color: #d4edda;
        border-color: #c3e6cb;
        padding: 0.75rem 1.25rem;
        margin-bottom: 1rem;
        border: 1px solid transparent;
        border-radius: 0.25rem;
        `,

  labelWithCheckbox: css`
  display: flex;
  align-items: center; // Vertically center align items
  gap: 10px; // Provide some gap between the label and checkbox
    `,
  checkbox: css`
      margin-left: 75px;
      height: 19px;
      width: 19px;
    }
`,
  backfillTitle: css`
    ${text.h3};
    margin-top: 30px;
    margin-bottom: 10px;
  `,
  backfillCheckbox: css`
    margin-left: auto;
    height: 19px;
    width: 19px;
  `
}


