import { css } from '@emotion/react';
import { format, subDays } from 'date-fns';
import React, { useEffect, useMemo, useState } from 'react';

import CardButton from '@/components/Buttons/CardButton';
import DataPreview from '@/components/DataPreview';
import DateRangeForm from '@/components/DateRangeForm';
import Section from '@/components/Section';
import { LocalStorage } from '@/config/constants';
import { RouteConfig } from '@/config/routes';
import { Breakpoint, ColorPalette, text } from '@/config/style';
import { useRouteParams } from '@/hooks/useRouteParams';
import { useTranslation } from '@/hooks/useTranslation';
import {
  Metric,
  METRICS,
  PROVIDERS,
  useAuthenticateProviderMutation,
  useRemoveProvidersMutation,
  UserIntegration,
  useUserQuery,
} from '@/services/app';
import { dateFormat } from '@/utils/dateTransformations/dateTransformation';
import { getFromLocalStorage, setToLocalStorage } from '@/utils/localStorage';

const getEnabledMetrics = (integrations: UserIntegration[] | undefined): Metric[] => {
  const metricSet = new Set<Metric>(
    integrations?.flatMap(({ categories }) => (Object.keys(categories) as Metric[]).filter((key) => categories[key])),
  );

  return [...metricSet];
};

const DemoWidgetPage = () => {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState<Metric | undefined>(undefined);
  const { appId } = useRouteParams(RouteConfig.Widget);
  const userId = getFromLocalStorage(`${appId}_userId`);
  const userQuery = useUserQuery(userId);
  const authenticateProviderMutation = useAuthenticateProviderMutation();
  const removeProvidersMutation = useRemoveProvidersMutation();
  const [dateRange, setDateRange] = useState<{ dateFrom: string | null; dateTo: string | null }>({
    dateFrom: format(subDays(new Date(), 2), dateFormat),
    dateTo: format(new Date(), dateFormat),
  });

  const integrations = userQuery.data?.integrations;
  const activeProviders = integrations?.map((item) => item.provider);
  const metrics = useMemo(() => getEnabledMetrics(integrations), [integrations]);
  const selectedMetric = METRICS.find((item) => item === currentTab);

  useEffect(() => {
    setToLocalStorage(LocalStorage.latestAppId, appId);
  }, [appId]);

  return (
    <div>
      <div css={styles.container}>
        <Section
          active={!!activeProviders?.length}
          loading={userQuery.isFetching || removeProvidersMutation.isLoading}
          title={t('msg_label_integrations')}
          initiallyExpanded
        >
          <div css={styles.cardRow}>
            {PROVIDERS.map((provider) => {
              const active = !!activeProviders?.includes(provider);
              return (
                <CardButton
                  key={provider}
                  active={active}
                  onClick={() => {
                    if (active) {
                      removeProvidersMutation
                        .mutateAsync({ providers: [provider], userId })
                        .then(() => setCurrentTab(undefined));
                      return;
                    }
                    authenticateProviderMutation.mutateAsync({ provider });
                  }}
                >
                  {t(`msg_provider_${provider}`)}
                </CardButton>
              );
            })}
          </div>
        </Section>

        <Section
          active={!!selectedMetric}
          disabled={!metrics.length || !userId}
          loading={userQuery.isFetching && userId !== null}
          title={t('msg_label_data_types')}
        >
          <div css={styles.cardRow}>
            {metrics.map((metric) => (
              <CardButton
                key={metric}
                onClick={() => {
                  setCurrentTab(metric);
                }}
                active={currentTab === metric}
              >
                {t(`msg_metric_${metric}`)}
              </CardButton>
            ))}
          </div>
        </Section>

        <Section
          active={!!dateRange.dateFrom && !!dateRange.dateTo}
          dashed={false}
          disabled={!userId || !selectedMetric}
          title={t('msg_label_select_date')}
          initiallyExpanded
        >
          <div>
            <DateRangeForm defaultValues={dateRange} onSubmit={setDateRange} />
            <div css={styles.dataPreviewContainer}>
              <span css={styles.accent} />
              {selectedMetric && (
                <DataPreview
                  metric={selectedMetric}
                  params={{ startDate: dateRange.dateFrom ?? '', endDate: dateRange.dateTo ?? '' }}
                />
              )}
            </div>
          </div>
        </Section>
      </div>
    </div>
  );
};

export default DemoWidgetPage;

const styles = {
  container: css`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    gap: 10px;

    flex-direction: column;
  `,

  cardRow: css`
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    align-items: center;

    ${Breakpoint.mobile} {
      gap: 10px;
    }
  `,
  dataDisplay: css`
    border: 1px solid ${ColorPalette.blue_700};
    display: flex;
    justify-content: center;
    padding: 10px;
  `,

  title: css`
    ${text.h4}
    padding-bottom: 20px;
  `,

  dataPreviewContainer: css`
    display: flex;
    flex-direction: row;

    border-radius: 6px;
    box-shadow: 0 4px 13px 0 rgba(0, 0, 0, 0.05);
    background: ${ColorPalette.white};

    ${Breakpoint.smallMobile} {
      flex-direction: column-reverse;
    }
  `,

  accent: css`
    display: flex;
    min-width: 22px;
    height: inherit;
    border-radius: 6px 0 0 6px;
    background-color: ${ColorPalette.blue_700};

    ${Breakpoint.smallMobile} {
      border-radius: 0 0 6px 6px;
      min-height: 22px;
      width: inherit;
    }
  `,
};
