import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { HelmetProvider } from 'react-helmet-async';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';

import { RouteConfig } from '@/config/routes';
import PrivateLayout from '@/routes/Layout/PrivateLayout';
import ApplicationPage from '@/routes/StaticPages/ApplicationPage';
import DemoWidgetPage from '@/routes/StaticPages/DemoWidgetPage';
import ExistingIntegrationsPage from '@/routes/StaticPages/ExistingIntegrationsPage';
import ExistingUsersPage from '@/routes/StaticPages/ExistingUsersPage';
import LoginPage from '@/routes/StaticPages/LoginPage';
import LoginPageEmail from '@/routes/StaticPages/LoginPageEmail';
import NotFoundPage from '@/routes/StaticPages/NotFoundPage';
import SuccessPage from '@/routes/StaticPages/SuccessPage';
import ApplicationEditPage from '@/routes/StaticPages/ApplicationEditPage';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
    mutations: {
      retry: false,
    },
  },
});

// TODO: add titles
const router = createBrowserRouter([
    // {
    //   path: RouteConfig.Login.template,
    //   element: <LoginPage />,
    // },
    {
      path: RouteConfig.LoginEmail.template,
      element: <LoginPageEmail />,
    },
  {
    path: RouteConfig.Home.template,
    element: <PrivateLayout />,
    // title: 'msg_home_page_title',
    children: [
      {
        path: RouteConfig.Home.template,
        element: <Navigate to={RouteConfig.Application.template} />,
      },
      {
        path: RouteConfig.Application.template,
        element: <ApplicationPage />,
      },
      {
        path: RouteConfig.Widget.template,
        element: <DemoWidgetPage />,
      },
      {
        path: RouteConfig.ExistingIntegrations.template,
        element: <ExistingIntegrationsPage />,
      },
      {
        path: RouteConfig.ExistingUsers.template,
        element: <ExistingUsersPage />,
      },
      {
        path: RouteConfig.ApplicationEdit.template,
        element: <ApplicationEditPage />,
      },
      {
        path: RouteConfig.SuccessPage.template,
        element: <SuccessPage />,
        // title: 'msg_success_page_title',
      },
      {
        path: '*',
        element: <Navigate to={RouteConfig.NotFound.template} />,
      },
      {
        path: RouteConfig.NotFound.template,
        element: <NotFoundPage />,
        // title: 'msg_not_found_page_title',
      },
    ],
  },
]);

const App = () => {
  return (
        <HelmetProvider>
        <QueryClientProvider client={queryClient}>
            <RouterProvider router={router} />
        </QueryClientProvider>
        </HelmetProvider>

  );
};

export default App;
