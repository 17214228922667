import { css } from '@emotion/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '@/components/Buttons/Button';
import { RouteConfig } from '@/config/routes';
import { Breakpoint, Color, ColorPalette, text } from '@/config/style';
import { useTranslation } from '@/hooks/useTranslation';

interface CardProps {
  company_name: string;
  application: string;
  email: string | undefined;
  client_id: string | undefined;
  client_token: string | undefined;
  color?: Color;
}

const Card = ({ company_name, email, client_id, client_token, color = 'blue_700' }: CardProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div css={styles.container}>
      <span css={styles.accent(color)} />
      <div css={styles.content}>
        <h4 css={styles.title(color)}>{company_name}</h4>
        <div css={styles.data}>
          {email && (
            <>
              <span css={styles.label}>{t('msg_card_admin_email')}</span>
              <span css={styles.value}>{email}</span>
            </>
          )}
          {client_id && (
            <>
              <span css={styles.label}>{t('msg_card_app_id')}</span>
              <span css={styles.value}>{client_id}</span>
            </>
          )}
          {client_token && (
            <>
              <span css={styles.label}>{t('msg_card_app_token')}</span>
              <span css={styles.value}>{client_token}</span>
            </>
          )}
        </div>
        <div css={styles.buttons}>
          <Button
            color={color}
            disabled={!client_id}
            onClick={() => navigate(RouteConfig.ApplicationEdit.buildLink({ clientId: client_id ?? '' }))}
          >
            {t('msg_card_button_demo_app')}
          </Button>
          {/*@TODO: uncomment when users page is available*/}
          {/*<Button variant="outlined" onClick={() => navigate(RouteConfig.ExistingUsers.buildLink())}>*/}
          {/*  {t('msg_card_button_see_app_users')}*/}
          {/*</Button>*/}
        </div>
      </div>
    </div>
  );
};

export default Card;

const styles = {
  container: css`
    display: flex;
    flex-direction: row;

    border-radius: 6px;
    box-shadow: 0 4px 13px 0 rgba(0, 0, 0, 0.05);
    background: ${ColorPalette.white};
  `,

  accent: (color: Color) => css`
    display: flex;
    min-width: 22px;
    height: inherit;
    border-radius: 6px 0 0 6px;
    background-color: ${ColorPalette[color]};

    ${Breakpoint.mobile} {
      min-width: 17px;
    }
  `,

  content: css`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 20px 30px;

    ${Breakpoint.mobile} {
      gap: 20px;
      padding: 15px;
    }
  `,

  title: (color: Color) => css`
    ${text.h4};
    color: ${ColorPalette[color]};
  `,

  data: css`
    display: grid;
    grid-template-columns: 100px auto;
    grid-row-gap: 10px;

    ${Breakpoint.mobile} {
      grid-template-columns: auto;
      grid-row-gap: 5px;
    }
  `,

  label: css`
    ${text.s};
    color: ${ColorPalette.grey_200};
  `,

  value: css`
    ${text.m};
    color: ${ColorPalette.grey_500};
  `,

  buttons: css`
    display: flex;
    gap: 20px;
    white-space: nowrap;

    ${Breakpoint.mobile} {
      gap: 10px;
    }
  `,
};
