import { css } from '@emotion/react';
import React, { ReactNode, useMemo } from 'react';

import Skeleton from '@/components/Skeleton';
import { ColorPalette, text } from '@/config/style';
import { useTranslation } from '@/hooks/useTranslation';
import { getRandomInt } from '@/utils/util';

import { Align, Column } from './types';
import { CELL_PADDING } from './utils';

type RowProps<TableItem> = {
  index: number;
  columns: Column<TableItem>[];
  content: (column: Column<TableItem>) => React.ReactNode;
};

export const Row = <TableItem extends object>({ index, columns, content }: RowProps<TableItem>) => {
  return (
    <RowContainer index={index}>
      {columns.map((column, columnIndex) => {
        return (
          <td key={columnIndex} css={styles.tableCell({ ...column })}>
            {content(column)}
          </td>
        );
      })}
    </RowContainer>
  );
};

const RowContainer = ({ children }: { index?: number; children: ReactNode }) => {
  return <tr css={styles.tableRow}>{children}</tr>;
};

export const EmptyRow = () => {
  const { t } = useTranslation();

  return (
    <RowContainer>
      <td css={styles.emptyTable}>{t('msg_common_no_data')}</td>
    </RowContainer>
  );
};

export const SkeletonCell = () => {
  const width = useMemo(() => getRandomInt(20, 80), []);

  return (
    <div css={styles.skeletonCell}>
      <Skeleton css={styles.skeleton} inline height={16} width={`${width}%`} />
    </div>
  );
};

const styles = {
  tableCell: ({
    align = Align.left,
    width,
    maxWidth,
    minHeight = 60,
  }: {
    align?: Align;
    width: number;
    maxWidth?: number;
    minHeight?: number;
  }) => css`
    ${text.s}
    color: ${ColorPalette.grey_500};

    min-width: 0;
    flex: ${width} 0 auto;
    width: ${width}px;

    ${maxWidth &&
    css`
      max-width: ${maxWidth}px;
    `}

    padding: ${CELL_PADDING}px 0;
    min-height: ${minHeight}px;

    display: flex;
    align-items: center;
    justify-content: ${align};
    word-break: break-word;
    white-space: normal;
  `,
  tableRow: css`
    display: flex;
    align-items: center;
    min-width: fit-content;
    border-top: 1px solid ${ColorPalette.grey_100};

    &:first-of-type {
      border-top: 0;
    }
  `,
  emptyTable: css`
    ${text.m}
    height: 100px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  skeleton: css`
    background-color: ${ColorPalette.grey_100};
    z-index: auto;
  `,
  skeletonCell: css`
    width: 100%;
  `,
};
