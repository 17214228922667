import { EventEmitter } from 'events';

export const localStorageEventEmitter = new EventEmitter();

export const setToLocalStorage = (name: string, value: string) => {
  localStorage.setItem(name, value);
  localStorageEventEmitter.emit('change');
};

export const deleteFromLocalStorage = (name: string): void => {
  localStorage.removeItem(name);
  localStorageEventEmitter.emit('change');
};

export const getFromLocalStorage = (name: string): string | null => {
  return localStorage.getItem(name) || null;
};
