import { css } from '@emotion/react';
import React from 'react';
import { Link } from 'react-router-dom';

import Button from '@/components/Buttons/Button';
import { RouteConfig } from '@/config/routes';
import { text } from '@/config/style';
import { useTranslation } from '@/hooks/useTranslation';

const NotFoundPage = () => {
  const { t } = useTranslation();

  return (
    <div css={styles.container}>
      <h1 css={text.h1}>404</h1>
      <span css={text.l}>{t('msg_not_found_page_title')}</span>
      <Link to={RouteConfig.Home.buildLink()}>
        <Button>{t('msg_go_back_home_button')}</Button>
      </Link>
    </div>
  );
};

export default NotFoundPage;

const styles = {
  container: css`
    height: 400px;
    display: grid;
    gap: 20px;
    align-content: center;
    justify-items: center;
  `,
};
