import { css } from '@emotion/react';
import React from 'react';

import LoadingSpinner from '@/components/LoadingSpinner';
import { ColorPalette, text } from '@/config/style';
import { InputSize } from '@/hooks/useInput';
import { isDefined } from '@/utils/types';

type LabelOptions = {
  isFocused: boolean;
  inputHasText: boolean;
  hasFieldError: boolean;
};

export type InputWrapperProps = {
  value: unknown;
  id: string;
  invalid?: boolean;
  error?: string;
  disabled?: boolean;
  children: React.ReactNode;
  placeholder?: string;
  isFocused?: boolean;
  label?: string;
  isLoading?: boolean;
  required?: boolean;
  size?: InputSize;
};

const InputWrapper = ({
  value,
  id,
  error,
  children,
  disabled = false,
  label,
  isFocused = false,
  isLoading = false,
  required = false,
  size = 'medium',
}: InputWrapperProps) => {
  return (
    <div css={styles.main}>
      <label
        htmlFor={id}
        aria-required={required}
        css={styles.label({
          isFocused,
          inputHasText: isDefined(value) && String(value).length > 0,
          hasFieldError: !!error,
        })}
      >
        {label}
        {required && <span css={styles.required}>*</span>}
      </label>
      <div css={styles.container({ disabled })}>
        <div css={styles.input}>
          <div css={styles.inputContainer(size)}>{children}</div>
        </div>
        {error && <p css={styles.errorLabel}>{error}</p>}
        {isLoading && (
          <div css={styles.loadingSpinnerWrapper}>
            <LoadingSpinner size={30} />
          </div>
        )}
      </div>
    </div>
  );
};

export default InputWrapper;

const styles = {
  main: css`
    display: flex;
    flex-direction: column;
    align-self: end;
    width: 100%;
  `,
  container: ({ disabled }: { disabled: boolean }) => css`
    display: flex;
    flex-direction: column;
    position: relative;
    background: transparent;
    flex: 1;

    ${disabled &&
    css`
      background-color: ${ColorPalette.grey_200};
      border: 1px solid ${ColorPalette.grey_200};
      border-radius: 6px;
    `}
  `,

  loadingSpinnerWrapper: css`
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
  `,

  inputContainer: (size: InputSize) => css`
    position: relative;
    display: flex;
    width: 100%;
    background: inherit;

    ${size === 'medium' &&
    css`
      height: 48px;
      min-height: 48px;
    `}

    ${size === 'small' &&
    css`
      height: 40px;
      min-height: 40px;
    `}
  `,

  label: (options: LabelOptions) => css`
    pointer-events: none;
    transition: all 0.1s linear;
    left: 0;
    color: ${options.isFocused ? ColorPalette.blue_400 : ColorPalette.blue_700};
    ${(options.isFocused || options.inputHasText || options.hasFieldError) &&
    css`
      padding-left: 2px;
    `}

    ${options.hasFieldError &&
    css`
      color: ${ColorPalette.error};
    `}
  `,

  required: css`
    color: ${ColorPalette.error};
  `,

  errorLabel: css`
    ${text.s}
    color: ${ColorPalette.error};
    position: absolute;
    bottom: -16px;
    white-space: nowrap;
  `,

  input: css`
    width: 100%;
    display: flex;
    background: inherit;
  `,
};
