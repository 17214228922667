import { css } from '@emotion/react';
import React from 'react';

import LoadingSpinner from '@/components/LoadingSpinner';
import { Color, ColorPalette, text } from '@/config/style';

type ButtonVariant = 'primary' | 'secondary' | 'outlined' | 'transparent';
type ButtonSize = 'big' | 'medium' | 'small' | 'icon';

interface ButtonProps {
  type?: 'submit' | 'button';
  children: React.ReactNode;
  variant?: ButtonVariant;
  size?: ButtonSize;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  loading?: boolean;
  color?: Color;
}

const Button = ({
  type = 'button',
  children,
  variant = 'primary',
  size = 'small',
  onClick,
  disabled,
  loading,
  color = 'blue_700',
}: ButtonProps) => (
  <button type={type} disabled={disabled || loading} css={styles.button(variant, size, color)} onClick={onClick}>
    {loading ? <LoadingSpinner color="white" size={23} /> : children}
  </button>
);

export default Button;

const styles = {
  button: (variant: ButtonVariant, size: ButtonSize, color: Color) => css`
    ${text.l_bold}

    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    border-radius: 6px;
    border: 1px solid;

    transition: 200ms all;
    cursor: pointer;
    padding: 8px 32px;

    ${size === 'big' &&
    css`
      min-height: 56px;
    `}

    ${size === 'medium' &&
    css`
      min-height: 48px;
    `}

    ${size === 'small' &&
    css`
      ${text.m_bold}
      min-height: 40px;
      padding: 8px;
    `}

    ${size === 'icon' &&
    css`
      padding: 0;
      min-height: 30px;
      min-width: 30px;
      width: 30px;
      border-radius: 4px;
    `}
    
    :not(:disabled):hover {
      background-color: ${ColorPalette.blue_400};
      border-color: ${ColorPalette.blue_400};
      color: ${ColorPalette.white};
      fill: ${ColorPalette.white};
    }

    :not(:disabled):active {
      background-color: ${ColorPalette.blue_500};
      border-color: ${ColorPalette.blue_500};
      color: ${ColorPalette.white};
      fill: ${ColorPalette.white};
    }

    :disabled {
      background-color: ${ColorPalette.grey_200};
      border-color: ${ColorPalette.grey_200};
      color: ${ColorPalette.grey_500};
      cursor: not-allowed;
    }

    ${variant === 'primary' &&
    css`
      background-color: ${ColorPalette[color]};
      border-color: ${ColorPalette[color]};
      color: ${ColorPalette.white};
      fill: ${ColorPalette.white};

      :disabled {
        background-color: ${ColorPalette.blue_100};
        border-color: ${ColorPalette.blue_100};
        color: ${ColorPalette.blue_50};
      }
    `}

    ${variant === 'outlined' &&
    css`
      background-color: transparent;
      border-color: ${ColorPalette.blue_700};
      color: ${ColorPalette.blue_700};
      fill: ${ColorPalette.blue_700};

      :disabled {
        border-color: ${ColorPalette.grey_100};
        background-color: ${ColorPalette.grey_100};
        color: ${ColorPalette.white};
        fill: ${ColorPalette.white};
      }
    `}

    ${variant === 'transparent' &&
    css`
      background-color: transparent;
      border: none;
      color: ${ColorPalette.blue_700};
      fill: ${ColorPalette.blue_700};

      :disabled {
        background-color: ${ColorPalette.grey_100};
        color: ${ColorPalette.white};
        fill: ${ColorPalette.white};
      }
    `}

    ${variant === 'secondary' &&
    css`
      background-color: ${ColorPalette.blue_400};
      border: none;
      color: ${ColorPalette.white};
      fill: ${ColorPalette.white};

      :not(:disabled):hover {
        background-color: ${ColorPalette.blue_300};
      }

      :not(:disabled):active {
        background-color: ${ColorPalette.blue_500};
      }

      :disabled {
        background-color: ${ColorPalette.grey_400};
        color: ${ColorPalette.grey_300};
        cursor: not-allowed;
      }
    `}
  `,
};
