import { css } from '@emotion/react';
import React from 'react';

import { ColorPalette, fontWeight, text } from '@/config/style';

interface SidebarButtonProps {
  children: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  active?: boolean;
}

const SidebarButton = ({ children, onClick, disabled, active }: SidebarButtonProps) => (
  <button type="button" disabled={disabled} css={styles.button(active)} onClick={onClick}>
    <span css={styles.content}>{children}</span>
  </button>
);

export default SidebarButton;

const styles = {
  content: css`
    display: flex;
    align-items: center;
    gap: 10px;
    min-height: inherit;
    width: 100%;
    background: transparent;
    border-radius: 0 6px 6px 0;
  `,

  button: (active?: boolean) => css`
    ${text.l};
    font-weight: ${fontWeight.bolderThanRegularButNotAsBoldAsSemiBold};

    min-height: 50px;
    width: 90%;
    padding-left: 20%;

    border: none;
    border-radius: 0 6px 6px 0;
    background-color: ${ColorPalette.blue_700};
    color: ${ColorPalette.white};

    transition: 200ms all;
    text-align: left;
    cursor: pointer;

    ${active &&
    css`
      background-color: ${ColorPalette.blue_400};
      box-shadow: 0 8px 14px rgba(0, 0, 0, 0.25);
    `}

    :not(:disabled) {
      :hover {
        background-color: ${ColorPalette.blue_400};
        box-shadow: 0 8px 14px rgba(0, 0, 0, 0.25);
      }

      :active {
        background-color: ${ColorPalette.blue_500};
        box-shadow: 0 8px 14px rgba(0, 0, 0, 0.25);
      }
    }

    :disabled {
      color: ${ColorPalette.grey_200};
      background-color: ${ColorPalette.grey_500};
      cursor: not-allowed;
    }
  `,
};
