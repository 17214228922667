import { css } from '@emotion/react';

import React, {useState, useEffect} from 'react';
import Card from '@/components/ApplicationCard';
import { LoadingSection } from '@/components/LoadingSpinner';
import NoData from '@/components/NoData';
import { Breakpoint } from '@/config/style';
import { useApplicationsQuery } from '@/services/application';
import ApiService from '@/services/api.service';

import { deleteFromLocalStorage, setToLocalStorage } from '@/utils/localStorage';
import { LocalStorage } from '@/config/constants';

import { useNavigate } from 'react-router-dom';
import { RouteConfig } from '@/config/routes';
const apiService = ApiService.getInstance()
const ApplicationPage = () => {
//   const applicationsQuery = useApplicationsQuery();

const navigate = useNavigate();
  const [allData, setAllData] = useState([])
  const fetchAll = () =>{
      apiService.request('users/client/').then((res) => {
          setAllData(res.data.results)})
          .catch(err=>{
            deleteFromLocalStorage(LocalStorage.token);
            deleteFromLocalStorage(LocalStorage.refreshToken);
            navigate(RouteConfig.LoginEmail.buildLink());
          })
  }

  useEffect(() => {
    fetchAll()
}, []);
//   if (applicationsQuery.isInitialLoading) {
//     return <LoadingSection />;
//   }

//   if (!applicationsQuery.data) {
//     return <NoData />;
//   }

  return (
    <div css={styles.container}>
      {allData.map((app, key) => (
        <Card key={key} {...app} />
      ))}
    </div>
  );
};

export default ApplicationPage;

const styles = {
  container: css`
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr;

    ${Breakpoint.tablet} {
      grid-template-columns: 1fr;
    }

    ${Breakpoint.mobile} {
      grid-template-columns: 1fr 1fr;
    }

    ${Breakpoint.smallMobile} {
      grid-template-columns: 1fr;
      gap: 10px;
    }
  `,
};
