import { useEffect, useState } from 'react';

import { LocalStorage } from '@/config/constants';
import { getFromLocalStorage, localStorageEventEmitter } from '@/utils/localStorage';

const getAppInfoFromLocalStorage = () => ({
  token: getFromLocalStorage(LocalStorage.token) || null,
});

export const useAppInfo = () => {
  const [appInfo, setAppInfo] = useState(getAppInfoFromLocalStorage());

  useEffect(() => {
    const listener = () => {
      setAppInfo(getAppInfoFromLocalStorage());
    };

    // Track changes by user
    window.addEventListener('storage', listener);
    // Track changes by application
    localStorageEventEmitter.addListener('change', listener);

    return () => {
      window.removeEventListener('storage', listener);
      localStorageEventEmitter.removeListener('change', listener);
    };
  }, []);
  return appInfo;
};
