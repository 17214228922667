import { css } from '@emotion/react';
import React, { useMemo, useState, useEffect } from 'react';

import Button from '@/components/Buttons/Button';
import Paginator from '@/components/Table/Paginator';
import Table, { SortDirection } from '@/components/Table/Table';
import { createColumns } from '@/components/Table/utils';
import { Breakpoint, ColorPalette, fontWeight, helperStyles, text } from '@/config/style';
import { useTranslation } from '@/hooks/useTranslation';
import { ExistingUser } from '@/services/user';

import ApiService from '@/services/api.service';

import { deleteFromLocalStorage } from '@/utils/localStorage';
import { LocalStorage } from '@/config/constants';

import { useNavigate } from 'react-router-dom';
import { RouteConfig } from '@/config/routes';
const apiService = ApiService.getInstance()
const ExistingUsersPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [currentSort, setCurrentSort] = useState<{ property: string; direction: SortDirection }>();
  const [paginationParams, setPaginationParams] = useState({ page: 0, pageSize: 10 });
  const [allData, setAllData] = useState([])

  // Fast fix for pagination, need to be refactored
  const fetchAll = () =>{apiService.request('users/end-users/?limit=1000&offset=0').then((res) => {
    setAllData(res.data.results)})
    .catch(err=>{
        deleteFromLocalStorage(LocalStorage.token);
        deleteFromLocalStorage(LocalStorage.refreshToken);
        navigate(RouteConfig.LoginEmail.buildLink());
      })
  }

  useEffect(() => {
        fetchAll()
    }, []);

    const visibleData = useMemo(() => {
        const { page, pageSize } = paginationParams;
        const startIndex = page * pageSize;
        const endIndex = startIndex + pageSize;
        return allData.slice(startIndex, endIndex);
    }, [allData, paginationParams]);
  const columns = useMemo(
    () =>
      createColumns<ExistingUser>([
        {
          Header: t('msg_existing_users_table_application'),
          width: 80,
          truncateText: true,
          accessor: (item) => item.company_name,
        },
        {
          Header: t('msg_existing_users_table_uuid'),
          width: 150,
          truncateText: true,
          accessor: (item) => item.uuid,
        },
        {
          Header: t('msg_existing_users_table_user_id'),
          width: 150,
          accessor: (item) => item.client_user_id,
        },
        {
          Header: t('msg_existing_users_table_created_at'),
          width: 130,
        //   sortProperty: 'created_at',
          accessor: (item) => item.created_at,
        },
//         {
//           Header: t('msg_existing_users_table_updated_at'),
//           width: 130,
//         //   sortProperty: 'updated_at',
//           accessor: (item) => item.updated_at,
//         },
        {
          Header: "",
          width: 100,
          accessor: (item) => item.button,
          Cell: item => (
              <Button
                onClick={() => {
                      apiService.post('api/users/delete-end-users/', {"uuid":item.uuid}).then(() => {
                          fetchAll()
                      })
                }}
                css
              > 
              {"Delete User"}
              </Button>
          )
        },
      ]),
    [t],
  );

  return (
    <div css={styles.container}>
      {/* <div css={styles.search}>
        <Input
          value={searchValue}
          onChange={setSearchValue}
          name="search"
          size="small"
          placeholder="Search"
          prefix={<SearchIcon css={styles.searchIcon} />}
        />
      </div> */}
      <div css={[helperStyles.paper, styles.paperContent]}>
        <Table
          columns={columns}
          data={visibleData}
          loading={false}
          currentSort={currentSort}
          onSortChange={setCurrentSort}
        />
        <Paginator
          currentPage={paginationParams.page}
          onPageChange={(newPage) => {
            setPaginationParams((prev) => ({ ...prev, page: newPage }));
          }}
          totalRows={allData.length}
          pageSize={paginationParams.pageSize}
          onPageSizeChange={(newPageSize) => {
            setPaginationParams((prev) => ({ ...prev, pageSize: newPageSize }));
          }}
        />
      </div>
    </div>
  );
};

export default ExistingUsersPage;

const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    gap: 20px;
  `,
  table: css`
    width: 100%;
  `,
  title: css`
    ${text.m};
    font-weight: ${fontWeight.semiBold};
    color: ${ColorPalette.blue_700};
  `,
  search: css`
    width: 420px;

    ${Breakpoint.tablet} {
      width: 100%;
    }
  `,
  searchIcon: css`
    fill: ${ColorPalette.grey_200};
  `,
  paperContent: css`
    display: flex;
    flex-direction: column;
    gap: 30px;
  `,
};
